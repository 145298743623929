import { SearchFilterNanoID } from '../searchFilters/searchFiltersTypes';
import { UserID, UserNanoID } from '../users/usersTypes';
import { TeamNanoID } from '../teams/teamsTypes';

import {
  ProjectID,
  ProjectNanoID,
  ProjectStatuses,
  ProjectUserID
} from './projectsTypes';
import { InvoiceNanoID } from '../invoices/invoicesTypes';

export class ProjectCache {
  static indexCacheKey() {
    return 'projects';
  }

  static compactIndexCacheKey() {
    return 'projects-compact';
  }

  static userActiveIndexCacheKey() {
    return 'projects-user-active';
  }

  static filteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `projects-filtered-${searchFilterNanoId}`;
  }

  static compactFilteredCacheKey(searchFilterNanoId: SearchFilterNanoID) {
    return `projects-compact-filtered-${searchFilterNanoId}`;
  }

  static statusTotalCountCacheKey(status: ProjectStatuses) {
    return `projects-total-count-${status}`;
  }

  static canceledTotalCountCacheKey() {
    return 'projects-canceled-total-count';
  }

  static burningCacheKey() {
    return 'projects-burning';
  }

  static burningTotalCountCacheKey() {
    return 'projects-burning-total-count';
  }

  static totalsCacheKey() {
    return 'projects-totals';
  }

  static favoriteCacheKey() {
    return 'projects-favorite';
  }

  static myProjectsCacheKey() {
    return 'my-projects';
  }

  static myTotalCountCacheKey() {
    return 'projects-my-total-count';
  }

  static invoiceProjectsCacheKey(invoiceNanoId: InvoiceNanoID) {
    return `projects-invoice-${invoiceNanoId}`;
  }

  static companyProjectsCacheKey(companyNanoId: TeamNanoID) {
    return `projects-company-${companyNanoId}`;
  }

  static compactTeamProjectsCacheKey(teamNanoId: TeamNanoID) {
    return `projects-compact-team-${teamNanoId}`;
  }

  static companyProjectsFavoriteCacheKey(companyNanoId: TeamNanoID) {
    return `projects-favorite-company-${companyNanoId}`;
  }

  static companyProjectsBurningCacheKey(companyNanoId: TeamNanoID) {
    return `projects-burning-company-${companyNanoId}`;
  }

  static userProjectsCacheKey(userNanoId: UserNanoID) {
    return `projects-user-${userNanoId}`;
  }

  static compactUserProjectsCacheKey(userNanoId: UserNanoID) {
    return `projects-compact-user-${userNanoId}`;
  }

  static userProfileProjectsCacheKey(userNanoId: UserNanoID) {
    return `projects-user-${userNanoId}-profile`;
  }

  static showCacheKey() {
    return 'project';
  }

  static showOldCacheKey() {
    return 'project-old';
  }

  static showUserCacheKey() {
    return 'project-user';
  }

  static showSidebarCacheKey() {
    return 'project-sidebar';
  }

  static showMembersCacheKey() {
    return 'project-members';
  }

  static showWhiteboardCacheKey() {
    return 'project-whiteboard';
  }

  static showStudioCacheKey() {
    return 'project-studio';
  }

  static showHeaderActionsCacheKey() {
    return 'project-header-actions';
  }

  static showFinCacheKey() {
    return 'fin-project';
  }

  static showRedirectCacheKey(projectId: ProjectID) {
    return `project-${projectId}-redirect`;
  }

  static selectFieldCacheKey() {
    return 'select-field-projects';
  }

  static companySelectFieldCacheKey(companyNanoId: TeamNanoID) {
    return `${companyNanoId}-select-field-projects`;
  }

  static selectByProducerFieldCacheKey(producerId: UserID) {
    return `select-by-producer-${producerId}-field-projects`;
  }

  static tasksCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks`;
  }

  static projectsTasksCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-projects-tasks`;
  }

  static tasksNamesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks-names`;
  }

  static tasksCompactCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks-compact`;
  }

  static canceledTasksCount(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-canceled-tasks`;
  }

  static hiddenTasksCount(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-hidden-tasks`;
  }

  static messagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-messages`;
  }

  static lastMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-last-messages`;
  }

  static firstMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-first-messages`;
  }

  static pinnedMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-pinned-messages`;
  }

  static favoriteMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-favorite-messages`;
  }

  static flaggedMessagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-flagged-messages`;
  }

  static forwardMessagesCacheKey() {
    return `projects-forward-messages`;
  }

  static projectMembersCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-members`;
  }

  static projectInvitesMembersCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-invited-members`;
  }

  static projectInvitesMembersCountCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-invited-members-count`;
  }

  static projectNotificationsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-project-notifications`;
  }

  static transactionsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-transactions`;
  }

  static transactionsCountCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-transactions-count`;
  }

  static attachmentsTasksCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-attachments-tasks`;
  }

  static filesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-files`;
  }

  static fileAttachmentsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-file-attachments`;
  }

  static imagesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-images`;
  }

  static tasksResultsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks-results`;
  }

  static taskResultsCountCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-tasks-results-count`;
  }

  static selectedProductsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-products`;
  }

  static selectedLifestylesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-lifestyles`;
  }

  static selectedMaterialsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-selected-materials`;
  }

  static sidebarFilesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-files`;
  }

  static sidebarMediaCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-sidebar-media`;
  }

  static billsCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-bills`;
  }

  static billsCountCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-bills-count`;
  }

  static showQueryKey(projectNanoId: ProjectNanoID) {
    return ['project', projectNanoId];
  }

  static userProjectsCountCacheKey(userId: ProjectUserID) {
    return `user-${userId}-projects-count`;
  }

  static userProjectTasksTableSettingsStateCacheKey() {
    return 'project-tasks-table-settings-state';
  }

  static projectsExpensesCacheKey(nanoIds: ProjectNanoID[]) {
    return `projects-expenses-${nanoIds.join('-')}`;
  }

  static showProjectExpensesCacheKey(projectNanoId: ProjectNanoID) {
    return `project-expenses-${projectNanoId}`;
  }

  static tasksMenuProjectsFilterCacheKey(userId: UserID) {
    return `tasks-menu-projects-filter-${userId}`;
  }

  static showInfoCacheKey(projectNanoId: ProjectNanoID) {
    return `project-${projectNanoId}-info`;
  }

  static indexSelectCacheKey(name: string) {
    return `select-field-projects-${name}`;
  }

  static selectedProjectSelectFieldCacheKey(name: string) {
    return `select-field-project-${name}`;
  }
}
