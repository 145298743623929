import { gql } from 'graphql-request';

import { InvoicesMetaAmountByStatus } from '../invoicesTypes';
import { MoneyType } from '../../../types';

export interface FetchProjectSidebarInvoicesQueryMetaResponse {
  amountByStatus: InvoicesMetaAmountByStatus;
  totalAmount: MoneyType;
}

export const FETCH_PROJECT_SIDEBAR_INVOICES_QUERY = gql`
  query ProjectSidebarInvoices(
    $filters: InvoicesFilters
    $sort: [InvoicesSortEnum!]
    $offset: Int
    $limit: Int
    $withTotalAmount: Boolean
  ) {
    invoices(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      withTotalAmount: $withTotalAmount
    ) {
      appVersion
      meta {
        amountByStatus {
          canceled
          draft
          none
          open
          paid
          sent
          uncollectible
          void
        }
        totalAmount
      }
      paginationInfo {
        currentPage
        firstPage
        lastPage
        limitValue
        nextPage
        outOfRange
        prevPage
        totalCount
        totalPages
      }
      sql
    }
  }
`;
